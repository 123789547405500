import {
  tallyOrgCompaniesAxios,
  productionUrl,
  tallyCompaniesListAxios,
  productionUrlForLogin,
  tallyStaticRepAxios,
  pushNotificationAxios,
  tallyCompanyDataSyncApi
} from "src/config/axios.config";

import axios from "axios";
import {
  SAVE_USER,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
  SAVE_USER_DEFAULT_STATUS,
  SAVE_ORGANISATION,
  SAVE_ORGANISATION_SUCCESS,
  SAVE_ORGANISATION_FAILURE,
  UPDATE_ORGANISATION,
  UPDATE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_FAILURE,
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  LOGGEDIN_USERS_ROLE,

  USER_LOGIN_FAILURE,
  GET_ORGANISATION_BY_ORG_ID,
  GET_ORGANISATION_BY_ORG_ID_SUCCESS,
  GET_ORGANISATION_BY_ORG_ID_FAILURE,
  GET_ORGANISATION_BY_ORG_ID_DEFAULT_STATUS,
  GET_ORGANISATION_BY_ORG_OWNER_ID,
  GET_ORGANISATION_BY_ORG_OWNER_ID_SUCCESS,
  GET_ORGANISATION_BY_ORG_OWNER_ID_FAILURE,
  GET_ORGANISATION_BY_ORG_OWNER_ID_DEFAULT_STATUS,

  EMAIL_TOKEN,
  EMAIL_TOKEN_SUCCESS,
  EMAIL_TOKEN_FAILURE,
  REQ_RESET_EMAIL,
  REQ_RESET_EMAIL_SUCCESS,
  REQ_RESET_EMAIL_ERROR,

  CONFIRM_PASSWORD,
  CONFIRM_PASSWORD_SUCCESS,
  CONFIRM_PASSWORD_ERROR,

  USER_REGISTER,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_ERROR,

  USER_EMAIl_EXISTS,
  USER_EMAIl_EXISTS_SUCCESS,
  USER_EMAIl_EXISTS_ERROR,

  GET_PROFILES,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_ERROR,

  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR,

  ASSIGN_USERS,
  ASSIGN_USERS_SUCCESS,
  ASSIGN_USERS_ERROR,


  GET_USER_COMPANIES_IN_LOGIN,
  GET_USER_COMPANIES_IN_LOGIN_SUCCESS,
  GET_USER_COMPANIES_IN_LOGIN_FAILURE,

  GET_COMPANY_PROFILE_INFO,
  GET_COMPANY_PROFILE_INFO_SUCCESS,
  GET_COMPANY_PROFILE_INFO_ERROR,

  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,

  INIT_USER_PROFILE_DATA,
  INIT_USER_PROFILE_DATA_SUCCESS,
  INIT_USER_PROFILE_DATA_ERROR,

  UPDATE_USER_PROFILE_DATA,
  UPDATE_USER_PROFILE_DATA_SUCCESS,
  UPDATE_USER_PROFILE_DATA_ERROR,

  GET_INVITED_USERS,
  GET_INVITED_USERS_SUCCESS,
  GET_INVITED_USERS_ERROR,

  GET_MY_TALLY_COMPANIES,
  GET_MY_TALLY_COMPANIES_SUCCESS,
  GET_MY_TALLY_COMPANIES_ERROR,
  GET_BOOK_START_AND_END_DATE_SUCCESS,
  GET_BOOK_START_AND_END_DATE,
  GET_BOOK_START_AND_END_DATE_FAILURE,
  USER_VERIFICATION,
  USER_VERIFICATION_SUCCESS,
  USER_VERIFICATION_FAILURE,
  REGISTER_INVITEE_FAILURE,
  REGISTER_INVITEE_SUCCESS,
  REGISTER_INVITEE,
  SAVE_SOCIAL_AUTH_DATA,
  SAVE_SOCIAL_AUTH_DATA_SUCCESS,
  SAVE_SOCIAL_AUTH_DATA_FAILURE,
  TOGGLE_SUBSCRIPTION_DATA_SUCCESS,
  TOGGLE_SUBSCRIPTION_DATA,
  TOGGLE_SUBSCRIPTION_DATA_FAILURE,
  NOTIFICATION_SUBSCRIPTION_DATA,
  RESEND_INVITATION_LINK,
  RESEND_INVITATION_LINK_SUCCESS,
  RESEND_INVITATION_LINK_FAILURE,
  VERIFY_RESET_PASSWORD,
  VERIFY_RESET_PASSWORD_SUCCESS,
  VERIFY_RESET_PASSWORD_FAILURE,
  SUBMIT_RESET_PASSWORD_DATA,
  SUBMIT_RESET_PASSWORD_DATA_SUCCESS,
  SUBMIT_RESET_PASSWORD_DATA_FAILURE,
  UPDATE_ASSIGN_USERS,
  UPDATE_ASSIGN_USERS_SUCCESS,
  UPDATE_ASSIGN_USERS_FAILURE,
  DELETE_ASSIGN_USERS_SUCCESS,
  DELETE_ASSIGN_USERS,
  DELETE_ASSIGN_USERS_FAILURE,
  GET_MY_TALLY_COMPANIES_NOTIFI,
  GET_MY_TALLY_COMPANIES_NOTIFI_SUCCESS,
  GET_MY_TALLY_COMPANIES_NOTIFI_FAILURE,
  RESEND_VERIFICATION_LINK,
  RESEND_VERIFICATION_LINK_SUCCESS,
  RESEND_VERIFICATION_LINK_FAILURE,
  GET_MY_TALLY_COMPANIES_AFTER_SYNC,
  GET_MY_TALLY_COMPANIES_AFTER_SYNC_SUCCESS,
  GET_MY_TALLY_COMPANIES_AFTER_SYNC_FAILURE,
  APPLE_SIGN_IN_FAILED,
  ADD_COMPANY_LOGO,
  ADD_COMPANY_LOGO_SUCCESS,
  ADD_COMPANY_LOGO_FAILURE,
  DELETE_TALLY_COMPANY,
  DELETE_TALLY_COMPANY_SUCCESS,
  DELETE_TALLY_COMPANY_FAILURE,
  UPDATE_SOCIA_AUTH_USER_MOBILE_NUM_SUCCESS,
  UPDATE_SOCIA_AUTH_USER_MOBILE_NUM,
  UPDATE_SOCIA_AUTH_USER_MOBILE_NUM_FAILURE,

} from "../actionTypes";
import Axios from "axios";
import { productionServiceUrl } from "src/util/serviceUrls";
// const dispatch = useDispatch()

const saveUser = (data) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: SAVE_USER,
  });

  return tallyOrgCompaniesAxios.post('registration/saveUser', data).then(
    (res) =>
      dispatch({
        type: SAVE_USER_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: SAVE_USER_FAILURE,
        payload: err,
      })
  );
};
const saveOrganisation = (formData) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: SAVE_ORGANISATION,
  });
  return tallyOrgCompaniesAxios.post('registration/insert/organaisation', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }).then(
    (res) =>
      dispatch({
        type: SAVE_ORGANISATION_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: SAVE_ORGANISATION_FAILURE,
        payload: err,
      })
  );
};
const updateOrganisation = (formData) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: UPDATE_ORGANISATION,
  });
  return tallyOrgCompaniesAxios.post('registration/update/organaisation', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }).then(
    (res) =>
      dispatch({
        type: UPDATE_ORGANISATION_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: UPDATE_ORGANISATION_FAILURE,
        payload: err,
      })
  );
};

const userLogin = (email, password) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: USER_LOGIN,
  });

  return productionUrlForLogin.post(`api/user_login/`, { "email": email, "password": password }).then(

    (res) => {

      const loginUserData = res.data;

      if (loginUserData.tallyCompaniesList.length > 0) {
        const userRole = loginUserData.tallyCompaniesList[0]?.roleName;
        dispatch({
          type: LOGGEDIN_USERS_ROLE,
          payload: { roleName: userRole },
        });

      }

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: loginUserData,
      });

    },
    (err) =>
      dispatch({
        type: USER_LOGIN_FAILURE,
        payload: err,
      })
  );
};

const emailVerify = (token) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: EMAIL_TOKEN,
  });

  return productionUrl.post('api/email_verify/', token).then(
    (res) => {
      dispatch({
        type: EMAIL_TOKEN_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: EMAIL_TOKEN_FAILURE,
        payload: err,
      })
  );
};



const reqResetEmail = (userEmailData) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: REQ_RESET_EMAIL,
  });

  return productionUrl.post('api/reset_password_email/', userEmailData).then(
    (res) => {
      dispatch({
        type: REQ_RESET_EMAIL_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: REQ_RESET_EMAIL_ERROR,
        payload: err,
      })
  );
};



const passwordResetComplete = (confirmPasswordDetails) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: CONFIRM_PASSWORD,
  });

  return productionUrl.post('api/password_reset_complete/', confirmPasswordDetails).then(
    (res) => {
      dispatch({
        type: CONFIRM_PASSWORD_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: CONFIRM_PASSWORD_ERROR,
        payload: err,
      })
  );
};


const userRegistration = (userDetails) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: USER_REGISTER,
  });

  return productionUrl.post('api/user_registration/', userDetails).then(
    (res) => {
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: USER_REGISTER_ERROR,
        payload: err,
      })
  );
};




const checkEmailExists = (checkUserDetailsData) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: USER_EMAIl_EXISTS,
  });

  return productionUrl.post('api/checkUserDetails/', checkUserDetailsData).then(
    (res) => {
      dispatch({
        type: USER_EMAIl_EXISTS_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: USER_EMAIl_EXISTS_ERROR,
        payload: err,
      })
  );
};

const getProfiles = () => (dispatch) => {
  dispatch({
    type: GET_PROFILES,
  });

  return productionUrl.get('api/getProfiles/').then(
    (res) => {
      dispatch({
        type: GET_PROFILES_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: GET_PROFILES_ERROR,
        payload: err,
      })
  );
};



const getRoles = () => (dispatch) => {
  dispatch({
    type: GET_ROLES,
  });

  return productionUrl.get('api/getRoles/').then(
    (res) => {
      dispatch({
        type: GET_ROLES_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: GET_ROLES_ERROR,
        payload: err,
      })
  );
};


const assignToUser = (userInvitationData) => (dispatch) => {
  dispatch({
    type: ASSIGN_USERS,
  });

  return productionUrl.post('api/user_invitation/', userInvitationData).then(
    (res) => {
      dispatch({
        type: ASSIGN_USERS_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: ASSIGN_USERS_ERROR,
        payload: err,
      })
  );
};

const assignToUserUpdate = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_ASSIGN_USERS,
  });
  return productionUrl.post('api/update_tally_company_user/', data).then(
    (res) => {
      dispatch({
        type: UPDATE_ASSIGN_USERS_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: UPDATE_ASSIGN_USERS_FAILURE,
        payload: err,
      })
  );
};

const deleteCompanyUser = (qdata) => (dispatch) => {
  dispatch({
    type: DELETE_ASSIGN_USERS,
  });
  return axios({
    method: 'delete',
    url: productionServiceUrl + "python/" + `api/delete_tally_company_user/`,
    data: qdata,
  }).then(
    (res) => {
      dispatch({
        type: DELETE_ASSIGN_USERS_SUCCESS,
        payload: res.data,
      })
    },
    (err) => {
      dispatch({
        type: DELETE_ASSIGN_USERS_FAILURE,
        payload: err,
      })
    }
  )
};
const deleteInvitation = (qdata) => (dispatch) => {
  dispatch({
    type: DELETE_ASSIGN_USERS,
  });
  return axios({
    method: 'delete',
    url: productionServiceUrl + "python/" + `api/delete_invitation/`,
    data: qdata,
  }).then(
    (res) => {
      dispatch({
        type: DELETE_ASSIGN_USERS_SUCCESS,
        payload: res.data,
      })
    },
    (err) => {
      dispatch({
        type: DELETE_ASSIGN_USERS_FAILURE,
        payload: err,
      })
    }
  )
};

const verifyUser = (token) => (dispatch) => {
  dispatch({
    type: USER_VERIFICATION,
  });

  return productionUrl.post('api/verify_invitation/', token).then(
    (res) => {
      dispatch({
        type: USER_VERIFICATION_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: USER_VERIFICATION_FAILURE,
        payload: err,
      })
  );
};

const verifyRegisteringUser = (token) => (dispatch) => {
  dispatch({
    type: USER_VERIFICATION,
  });

  return productionUrl.post('api/verify_registration/', token).then(
    (res) => {
      dispatch({
        type: USER_VERIFICATION_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: USER_VERIFICATION_FAILURE,
        payload: err,
      })
  );
};
const resendEmailForVerification = (data) => (dispatch) => {
  dispatch({
    type: RESEND_INVITATION_LINK,
  });

  return productionUrl.post('api/resend_verification_link/', data).then(
    (res) => {
      dispatch({
        type: RESEND_INVITATION_LINK_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: RESEND_INVITATION_LINK_FAILURE,
        payload: err,
      })
  );
};

const registerUnRegisteredUser = (data) => (dispatch) => {
  dispatch({
    type: REGISTER_INVITEE,
  });

  return productionUrl.post('api/register_invitee/', data).then(
    (res) => {
      dispatch({
        type: REGISTER_INVITEE_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: REGISTER_INVITEE_FAILURE,
        payload: err,
      })
  );
};



const getOrganisationById = (id) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: GET_ORGANISATION_BY_ORG_ID,
  });

  return tallyOrgCompaniesAxios.post(`registration/getOrganisationDetailsByOrgId?orgId=${id}`, {}).then(
    (res) =>
      dispatch({
        type: GET_ORGANISATION_BY_ORG_ID_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: GET_ORGANISATION_BY_ORG_ID_FAILURE,
        payload: err,
      })
  );
};

const getOrganisationsListByOwnerId = (id) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: GET_ORGANISATION_BY_ORG_OWNER_ID,
  });

  return tallyOrgCompaniesAxios.post(`registration/getOrganisationListByOwnerId?ownerId=b1d8d885-705f-4c0d-9ac4-b95df5077c14`, {}).then(
    (res) =>
      dispatch({
        type: GET_ORGANISATION_BY_ORG_OWNER_ID_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: GET_ORGANISATION_BY_ORG_OWNER_ID_FAILURE,
        payload: err,
      })
  );


};

const getCompanyProfileInfo = (tc_uuid) => (dispatch) => {
  dispatch({
    type: GET_COMPANY_PROFILE_INFO,
  });

  return productionUrl.post('api/get_tally_company_info/', tc_uuid).then(
    (res) =>

      dispatch({
        type: GET_COMPANY_PROFILE_INFO_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: GET_COMPANY_PROFILE_INFO_ERROR,
        payload: err,
      })
  );
};

const updateProfilePassword = (updatePassParam) => (dispatch) => {
  dispatch({
    type: UPDATE_PASSWORD,
  });

  return productionUrl.post('api/update_user_password/', updatePassParam).then(

    (res) =>
      dispatch({
        type: UPDATE_PASSWORD_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: UPDATE_PASSWORD_ERROR,
        payload: err,
      })
  );
};

const initUserProfileData = (uuid) => (dispatch) => {
  dispatch({
    type: INIT_USER_PROFILE_DATA,
  });

  return productionUrl.post('api/get_user_profile_info/', uuid).then(
    (res) =>
      dispatch({
        type: INIT_USER_PROFILE_DATA_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: INIT_USER_PROFILE_DATA_ERROR,
        payload: err,
      })
  );
};

const updateUserProfileData = (formdata) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_PROFILE_DATA,
  });

  return productionUrl.post('api/update_user_profile_info/', formdata).then(

    (res) =>
      dispatch({
        type: UPDATE_USER_PROFILE_DATA_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: UPDATE_USER_PROFILE_DATA_ERROR,
        payload: err,
      })
  );
};


const getInvitedUsers = (uuid) => (dispatch) => {
  dispatch({
    type: GET_INVITED_USERS,
  });

  return productionUrl.post('api/get_tally_company_users/', uuid).then(

    (res) =>
      dispatch({
        type: GET_INVITED_USERS_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: GET_INVITED_USERS_ERROR,
        payload: err,
      })
  );
};

const getOrganisationsListByUserId = (userId) => (dispatch) => {
  dispatch({
    type: GET_MY_TALLY_COMPANIES,
  });

  return tallyOrgCompaniesAxios.get(`tally/companies/getTallyCompaniesByuserId?userId=${userId}`).then(

    (res) =>
      dispatch({
        type: GET_MY_TALLY_COMPANIES_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: GET_MY_TALLY_COMPANIES_ERROR,
        payload: err,
      })
  );
};

const deleteTallyCompany = (data, isOwner) => (dispatch) => {
  dispatch({
    type: DELETE_TALLY_COMPANY,
  });
  if (isOwner) {
    return tallyCompanyDataSyncApi.post(`company/deleteTallyCompany`, data).then(

      (res) =>
        dispatch({
          type: DELETE_TALLY_COMPANY_SUCCESS,
          payload: res.data,
        }),
      (err) =>
        dispatch({
          type: DELETE_TALLY_COMPANY_FAILURE,
          payload: err,
        })
    );
  } else {
    return tallyOrgCompaniesAxios.post(`tally/companies/deletCompanyByUserIdAndTcuId`, data).then(
      (res) =>
        dispatch({
          type: DELETE_TALLY_COMPANY_SUCCESS,
          payload: res.data,
        }),
      (err) =>
        dispatch({
          type: DELETE_TALLY_COMPANY_FAILURE,
          payload: err,
        })
    );
  }
};

const getOrganisationsListByUserIdForNotifications = (userId) => (dispatch) => {
  dispatch({
    type: GET_MY_TALLY_COMPANIES_NOTIFI,
  });

  return tallyCompaniesListAxios.get(`tallyuserorgcompanies/tally/companies/getTallyCompaniesByuserId?userId=${userId}`).then(

    (res) =>
      dispatch({
        type: GET_MY_TALLY_COMPANIES_NOTIFI_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: GET_MY_TALLY_COMPANIES_NOTIFI_FAILURE,
        payload: err,
      })
  );
};

const getOrganisationsListAfterSync = (userId) => (dispatch) => {
  dispatch({
    type: GET_MY_TALLY_COMPANIES_AFTER_SYNC,
  });

  return tallyCompaniesListAxios.get(`tallyuserorgcompanies/tally/companies/getTallyCompaniesByuserId?userId=${userId}`).then(

    (res) =>
      dispatch({
        type: GET_MY_TALLY_COMPANIES_AFTER_SYNC_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: GET_MY_TALLY_COMPANIES_AFTER_SYNC_FAILURE,
        payload: err,
      })
  );
};

const getBookStartAndEndDate = () => (dispatch) => {
  dispatch({
    type: GET_BOOK_START_AND_END_DATE,
  });
  return tallyStaticRepAxios.get(`reports/getBooksFromDateToDate`).then(

    (res) =>
      dispatch({
        type: GET_BOOK_START_AND_END_DATE_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: GET_BOOK_START_AND_END_DATE_FAILURE,
        payload: err,
      })
  );
};


const saveSocialAuthData = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SOCIAL_AUTH_DATA,
  });
  return productionUrl.post(`api/social_auth_login/`, data).then(

    (res) => {
      dispatch({
        type: SAVE_SOCIAL_AUTH_DATA_SUCCESS,
        payload: res.data,
      })

      console.log(res.data)
    },
    (err) =>
      dispatch({
        type: SAVE_SOCIAL_AUTH_DATA_FAILURE,
        payload: err,
      })
  );
};

const saveSocialAuthMobileNum = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_SOCIA_AUTH_USER_MOBILE_NUM,
  });
  return productionUrl.post(`api/update_phone_number/`, data).then(

    (res) =>
      dispatch({
        type: UPDATE_SOCIA_AUTH_USER_MOBILE_NUM_SUCCESS,
        payload: res?.data,
      }),
    (err) =>
      dispatch({
        type: UPDATE_SOCIA_AUTH_USER_MOBILE_NUM_FAILURE,
        payload: err,
      })
  );
};
const toggleSubScriptionData = (data) => (dispatch) => {
  dispatch({
    type: TOGGLE_SUBSCRIPTION_DATA,
  });
  return pushNotificationAxios.post(`api/toggle_subscription/`, data).then(

    (res) =>
      dispatch({
        type: NOTIFICATION_SUBSCRIPTION_DATA,
        payload: res?.data?.data,
      }),
    (err) =>
      dispatch({
        type: TOGGLE_SUBSCRIPTION_DATA_FAILURE,
        payload: err,
      })
  );
};
const verifyRestPassword = (data) => (dispatch) => {
  dispatch({
    type: VERIFY_RESET_PASSWORD,
  });

  return productionUrl.post('api/verify_reset_password/', data).then(
    (res) => {
      dispatch({
        type: VERIFY_RESET_PASSWORD_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: VERIFY_RESET_PASSWORD_FAILURE,
        payload: err,
      })
  );
};

const submitResetPassword = (data) => (dispatch) => {
  dispatch({
    type: SUBMIT_RESET_PASSWORD_DATA,
  });

  return productionUrl.post('api/reset_password/', data).then(
    (res) => {
      dispatch({
        type: SUBMIT_RESET_PASSWORD_DATA_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: SUBMIT_RESET_PASSWORD_DATA_FAILURE,
        payload: err,
      })
  );
};

const resendVeificatinLink = (data) => (dispatch) => {
  dispatch({
    type: RESEND_VERIFICATION_LINK,
  });

  return productionUrl.post('api/resend_verification_link/', data).then(
    (res) => {
      dispatch({
        type: RESEND_VERIFICATION_LINK_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: RESEND_VERIFICATION_LINK_FAILURE,
        payload: err,
      })
  );
};


const appleAuth = (params) => {
  return (dispatch) => {
    return axios({
      method: 'POST',
      url: productionServiceUrl + 'python/api/apple_login/',
      data: params,
    }).then(res => res)
      .then(response => {
        if (response.data.loginValidateStatus) {
          dispatch({
            type: SAVE_SOCIAL_AUTH_DATA_SUCCESS,
            payload: response.data,
          })
        } else {
          dispatch({ type: APPLE_SIGN_IN_FAILED, payload: response.data.response })
        }
      })
  }
};

const addCompanyLogo = (data) => (dispatch) => {
  dispatch({
    type: ADD_COMPANY_LOGO,
  });

  return productionUrl.post('api/upload_company_logo/', data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then(
    (res) => {
      dispatch({
        type: ADD_COMPANY_LOGO_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: ADD_COMPANY_LOGO_FAILURE,
        payload: err,
      })
  );
};
export {
  getOrganisationsListByUserId,
  getInvitedUsers,
  updateUserProfileData,
  initUserProfileData,
  updateProfilePassword,
  getCompanyProfileInfo,
  getProfiles, assignToUser,
  getRoles,
  passwordResetComplete,
  checkEmailExists,
  userRegistration,
  saveUser,
  saveOrganisation,
  userLogin, getOrganisationById,
  getOrganisationsListByOwnerId,
  updateOrganisation,
  emailVerify,
  reqResetEmail,
  getBookStartAndEndDate,
  verifyUser,
  registerUnRegisteredUser,
  saveSocialAuthData,
  toggleSubScriptionData,
  verifyRegisteringUser,
  resendEmailForVerification,
  verifyRestPassword,
  submitResetPassword,
  assignToUserUpdate,
  deleteCompanyUser,
  deleteInvitation,
  getOrganisationsListByUserIdForNotifications,
  resendVeificatinLink,
  getOrganisationsListAfterSync,
  appleAuth,
  addCompanyLogo,
  deleteTallyCompany,
  saveSocialAuthMobileNum
};
