import {
  FETCH_NARRATION_TEXT_ANALYSIS,
  FETCH_NARRATION_TEXT_ANALYSIS_SUCCESS,
  FETCH_NARRATION_TEXT_ANALYSIS_FAILURE,
} from "src/actions/actionTypes";

const initialState = {
  narrationData: {},
};

export default function narrationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_NARRATION_TEXT_ANALYSIS_SUCCESS: {
      return { ...state, narrationData: action.payload };
    }

    default:
      return state;
  }
}
