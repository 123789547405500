import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Logofull from "src/assets/icons/logo-large.png";
import {
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CRow
} from '@coreui/react'
import { CLEAR_AFER_LOGIN, RESEND_VERIFICATION_LINK_SUCCESS, RESET_SOCIAL_AUTH_DATA } from 'src/actions/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { resendVeificatinLink } from 'src/actions/registration';
import ToastMessage from 'src/components/toast-message/ToastMessage';



const Activationpending = () => {
    const history = useHistory()
    const verificationPendingData = useSelector(state => state.registration?.verificationPendingData)
    const resendVerificatinLinkResp = useSelector(state => state.registration?.resendVerificatinLinkResp)
    const [responseMsg, setResponseMsg] = useState('');
    const [ToastSeverity, setToastSeverity] = useState('');
    const [showToast, setshowToast] = useState(false);
    const [loaderButton, setloaderButton] = useState(false)

    useEffect(() => {
        if (verificationPendingData) {

        } else {
            history.push("/login")
        }
    }, [verificationPendingData])
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({ type: CLEAR_AFER_LOGIN, payload: [] });
        dispatch({ type: RESET_SOCIAL_AUTH_DATA, payload: [] });
    }, [])
    useEffect(() => {

        return () => {
            dispatch({ type: RESEND_VERIFICATION_LINK_SUCCESS, payload: null });
        }
    }, [])
    useEffect(() => {
        if (resendVerificatinLinkResp) {
            if (resendVerificatinLinkResp?.status) {
                setToastSeverity("success")
                setResponseMsg(resendVerificatinLinkResp?.response)
                setshowToast(true)
            } else {
                setToastSeverity("error")
                setResponseMsg(resendVerificatinLinkResp?.response)
                setshowToast(true)
            }
            setloaderButton(false)
        } else {

        }
    }, [resendVerificatinLinkResp])
    const onResendVeificatinLink = () => {
        setshowToast(false)
        setloaderButton(true)
        const data = {
            "email": verificationPendingData?.email
        }
        dispatch(resendVeificatinLink(data))
    }

    return (
        <>

            {showToast && (
                <ToastMessage
                    severity={ToastSeverity}
                    summary={ToastSeverity == "error" ? "Error" : "Success"}
                    detail={responseMsg}
                />
            )}
            <div className="">
                <div className="c-app c-default-layout flex-row align-items-center mdb-shadow">
                    <CContainer>
                        <CRow className="justify-content-center">
                            <CCol xl="8" lg="8" md="12" sm="12">
                                <CCard className="border-0">
                                    <CCardBody className="p-0">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                                <div className="p-2 verify-bg">
                                                    <img src={Logofull} className="mb-3 c-sidebar-brand-full mobile-logo-width img-fluid" />{" "}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pt-5 pb-5 pl-5 pr-5 inside-border-radius">
                                            <div className="row mb-4">
                                                <div className="col-md-5 text-center">
                                                    <div className="success-full right-line-border">
                                                        <span className="verify-success-icon-view">
                                                            <i className="fas fa-user" aria-hidden="true"></i>
                                                        </span>
                                                        <h5 className="bold-label mb-4 mt-4">Account Activation Pending</h5>
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <div className="pl-2 pr-2 pt-4">
                                                        <h6 className="status-font mb-4 line-height-custom">Email Verification is pending for <span className="bold-label text-primary">{verificationPendingData?.email}</span>. Kindly activate with the email sent or generate a new verification link here.</h6>
                                                        {!loaderButton && <button className="btn btn-primary btn-lg status-font btn-shadow" onClick={() => onResendVeificatinLink()}><i className="fas fa-undo"></i> Resend Link</button>}
                                                        {loaderButton && <button className="btn btn-primary btn-lg status-font btn-shadow" ><i className="fa fa-circle-o-notch fa-spin"></i> Resend Link</button>}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <h6 classname="status-font mb-2 line-height-custom min-font-size mt-4"><i>If the email is not found in your Inbox, please check in your Junk/Spam/allmail folder.</i></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </CCardBody>
                                </CCard>

                                <h6 className="text-center">Already Have an Account? <Link to="/login" className="text-success bold-label">Login</Link></h6>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </div>

        </>
    )
}


export default Activationpending
