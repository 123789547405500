// ------------------------------------------- URL for APIS ----------------------------------------------

export const productionServiceUrl = process.env.REACT_APP_ENVIRONMENT === "dev" ? "https://devservices.finsights.biz/" : "https://services.finsights.biz/"

// -------------------------------------------END OF URL for APIS ----------------------------------------------

// ------------------------------------------- OAUTH CLIENT IDS ----------------------------------------------

export const oneSignalAppId = process.env.REACT_APP_ENVIRONMENT === "dev" ? "b3c82698-78c7-4bf1-98bf-e47bd6a808a3" : "71bb5757-992c-475f-a550-112a75d032a2"
export const appleRedirectUri = process.env.REACT_APP_ENVIRONMENT === "dev" ? "https://dev.finsights.biz/apple/callback/" : "https://app.finsights.biz/apple/callback/"
export const appleClientId = "biz.finsights.app.sid"
export const googleClientId = "241808655870-olcfquuv6nelihnsvugs0knctlv684b9.apps.googleusercontent.com"
export const googleRedirectUri = "https://app.finsights.biz/accounts/google/login/callback/"

// ------------------------------------------- END OAUTH CLIENT IDS ----------------------------------------------